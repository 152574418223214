@import '../css/angular-csp.css';
@import 'reset';
@import 'variables';
@import 'breadcrumbs';

@font-face {
	font-family: 'SansSerifBldFLF';
  src: url('../font/ssbf.woff2') format('woff2'), url('../font/ssbf.woff') format('woff');
  font-display: swap;
}

html {
  height: auto;
  position: relative;
}

body {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  background: $color2;

  &.wrapper {
    height: 100vh !important;
  }
}

*:focus {
  outline: none;
}

::selection {
  background: $color;
  color: $color2;
}

// ins.adsbygoogle[data-ad-status="unfilled"] {
//   display: none !important;
// }

a[disabled],
:disabled {
  pointer-events: none;
}

a {
  color: $color;
  display: flex;
  text-decoration: none;
  cursor: pointer;

  &.inactive {
    pointer-events: none;
    color: $color;

    &:hover {
      color: inherit;
      opacity: $opacity;
    }
  }

  &:hover {
    opacity: $opacity !important;
  }
}

b,
strong {
  font-weight: 600;
}

button {
	all: unset;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:hover {
    opacity: $opacity;
  }

	[disabled],
	:disabled {
	  pointer-events: none;
	}

  &.inactive {
    pointer-events: none;
    color: $color;

    &:hover {
      color: inherit;
      opacity: $opacity;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  &__container {
    flex: 1;
  }
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px;

  &__title {
    text-align: center;
    margin-top: 2rem;
    font-size: $font-size5;
    font-weight: 400;
    text-transform: lowercase;
  }

  &__2x {
    text-align: center;

    &:empty {
      display: none !important;
    }

    &_top {
      margin: 1rem 0 0;
    }

    &_bottom {
      margin: 2rem 0;
    }

    a {
      display: flex;
      justify-content: center;
    }
  }
}

.submenu__list {
  background: $color2;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 1.5rem;
  position: absolute;
  top: 100%;
  z-index: 102;
  margin-top: .5rem;
  right: 25%;
  transform: translate(25%, 0);
}

.close__submenu,
.modal__close,
.cookie__close,
.options__close {
  cursor: pointer;
  position: relative;

  &:before {
    @include font-awesome;
    content: "\f00d";
    font-size: $font-size4;
    font-weight: 100;
    line-height: 1;
  }
}

.checkbox {
  height: 1rem;
  margin: 0;
  vertical-align: top;
  width: 1rem;
}

.checkbox + label {
  cursor: pointer;
}

.checkbox:not(checked) {
  opacity: 0;
  outline: 0;
  position: absolute;
}

.checkbox:not(checked) + label {
  display: block;
  padding: 0 0 0 28px;
  position: relative;
}

.checkbox:not(checked) + label:before {
  @include font-awesome;
  content: "\f0c8";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.checkbox:not(checked) + label:after {
  @include font-awesome;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.checkbox:checked + label:after {
  content: "\f14a";
}

.radio {
  height: 1rem;
  margin: 0 5px 0 0;
  vertical-align: top;
  width: 1rem;

  & + label {
    cursor: pointer;
  }

  &:not(checked) {
    opacity: 0;
    position: absolute;

    & + label {
      display: block;
      padding: 0 0 0 1.5rem;
      position: relative;

      &:before {
        @include font-awesome;
        content: '\f111';
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &:after {
        @include font-awesome;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}

.radio:not(checked) + label:hover:after {
  background: none;
}

.radio:checked + label:hover:after,
.radio:checked + label:after {
  content: '\f058';
}

.radio:focus + label:before {
}

.toggle {
  height: 1rem;
  margin: 0;
  vertical-align: top;
  width: 1rem;
}

.toggle + label {
  cursor: pointer;
}

.toggle:not(checked) {
  display: none;
}

.toggle:not(checked) + label {
  display: block;
  padding: 0 0 0 28px;
  position: relative;
}

.toggle:not(checked) + label:before {
  @include font-awesome;
  content: "\f111";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 300;
}

.toggle:not(checked) + label:after {
  @include font-awesome;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 300;
}

.toggle:checked + label:before {
  opacity: 0;
}

.toggle:checked + label:after {
  content: "\f057";
}

.block__height {
  height: 765px;
}

.header {
  padding: 1rem 0;
  //box-shadow: $box-shadow2;
  border-bottom: 1px solid;

  &__container {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1200px;
    flex-direction: column;
    gap: 1rem;
  }

  &__logo {
    text-align: center;
    user-select: none;

    a {
			font-family: 'SansSerifBldFLF', sans-serif;
      font-size: 30px;
      line-height: .7;
      //flex-direction: column;
      //background: $color;
      //color: $color2;
      //padding: 1rem;
      opacity: 1 !important;
    }
  }

  &__info {
    font-size: $font-size6;
    width: 400px;

    &-content {
      overflow: hidden;
      padding: .5em;
      position: relative;
    }
  }

  &__nav {
    &-list {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem 2rem;

      a {
        align-items: center;
        gap: .5rem;

        &:hover {
          color: $color;
        }
      }

      &_inner {
        display: none;
      }
    }

    .nav__content-list {
      gap: 1rem 2rem;
    }
  }

  &__toggle {
    .toggle:not(checked) + label:before {
      content: none;
    }

    .toggle:checked + label:after {
      content: none;
    }

    .toggle:not(checked) + label {
      padding: 0;
      color: $color;
      display: flex;
      gap: .5rem;
      align-items: center;

      &:hover {
        opacity: $opacity;
      }
    }
  }

  &__lang-form {
    display: none;
  }
}

.nav {
  margin: 1rem 0 1rem;
  user-select: none;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__content {
    display: flex;
    align-items: center;

    &-list {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      a {
        align-items: center;
        gap: .5rem;
      }

      .nav__content-link_disabled {
        &[disabled],
        &:disabled {
          opacity: 1;
          pointer-events: fill;
        }
      }

      li, .nav__options {
        white-space: nowrap;

        &.has-submenu {
          position: relative;

          &.open-submenu {
            .nav__content-list_inner,
            .options {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
            }

            .nav__content-list_inner {
              right: 50%;
              transform: translate(50%, 0);
              display: flex;
              gap: .5rem;
              flex-direction: column;
            }
          }
        }
      }

      &_inner,
      .options {
        display: none;

				.options__content-list {
					button {
	          opacity: 1 !important;
	        }
				}
      }

      &_overflow {
        overflow: auto;
        height: 350px;
      }
    }

    &-info {
      color: $color;
      display: flex;
      gap: 1rem;

      &_muted {
        color: $color;

        &:not([disabled]):hover {
          color: $color;
        }
      }

      &_block {
        display: flex;
        gap: 5.25px; // ширина пробела

        a {
          color: $color;
        }
      }
    }
  }

  &__options {
    &.open-submenu {
      .nav__options-overlay {
        background-color: $background;
        bottom: 0;
        display: block;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 103;
      }
    }

    .options {
      box-shadow: none;
      left: 50%;
      padding: 0;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 800px;
      z-index: 104;
      margin: 0;

      &__close {
        position: absolute;
        align-items: baseline;
        top: 0;
        right: 21px;

        &:hover {
          opacity: $opacity;
        }
      }

      &__content {
        overflow: auto;
        max-height: 290px;

        input[type="checkbox"] {
          display: none;
        }
      }

      &__header {
        margin-top: 1.5rem;

        &-title {
          font-size: $font-size4;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          gap: .5rem;
        }
      }

      .checkbox:not(checked) {
        & + label:after {
          opacity: 0;
          top: 0;
          transform: none;
        }

        & + label:before {
          top: 0;
          transform: none;
        }
      }

      .checkbox:checked {
        & + label:before {
          opacity: 0;
        }

        & + label:after {
          opacity: 1;
        }
      }

      .toggle:checked {
        & + label:before {
          opacity: 0;
        }

        & + label:after {
          opacity: 1;
        }
      }
    }

    &-meta {
			display: flex;
			justify-content: center;
			font-size: $font-size2;
			color: $color4;
			@include text-decoration;
    }
  }
}

.score {
  display: inline-block;
}

.info-container {
  background: $color6;
  padding: 1rem;
  font-size: $font-size2;
  margin-top: 1rem;

  &__item:not(:last-child) {
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid;
  }

	&__title {
    font-size: 28px;
    margin: 0 0 1rem;
    font-weight: 400;
    text-align: center;
    text-transform: lowercase;
  }

  &__content {
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin: 1.5rem 0 1rem;
      text-align: center;
      text-transform: lowercase;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      margin: 1rem 0;
      text-align: center;
      text-transform: lowercase;
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      margin: 1rem 0;
      text-align: center;
      text-transform: lowercase;
    }

    p {
      line-height: 1.4;
      margin: 1rem 0 0;

      a {
        display: inline;
        border-bottom: 1px solid;
      }
    }

    ul,
    ol {
      list-style: disc;
      margin: 1rem 0 0 2rem;
    }

    li {
      margin: .5rem 0 0;
    }

    img {
      display: none;
    }

    &_about {
	    h3 {
		    font-size: $font-size;
	    }
    }
  }

  &__subpage {
    &-list {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: center;
	    gap: .25rem 0;
	    text-transform: lowercase;

			a {
		    display: initial;
		    border-bottom: 1px solid;
			}
    }

		&-info {
	    margin-top: 2rem;
	    padding-top: 1rem;
	    border-top: 1px solid;
	    display: flex;
	    gap: 0 1rem;
	    justify-content: center;

			a {
		    border-bottom: 1px solid;
			}
		}
  }
}

.page {
  margin: 2rem 0 0;

  &__title {
    font-size: $font-size5;
    margin: 0 0 2rem;
    font-weight: 400;
    text-align: center;
  }

  &__content {
    h2 {
      font-size: $font-size3;
      font-weight: 400;
      margin: 1.5rem 0;
      text-align: center;
    }

    h3 {
      font-size: $font-size;
      font-weight: 400;
      margin: 1rem 0;
      text-align: center;
    }

    p {
      line-height: 1.4;
      margin: 1rem 0 0;

      a {
        display: inline;
        border-bottom: 1px solid;
      }
    }

    ul,
    ol {
      list-style: disc;
      margin: 1rem 0 0 2rem;
    }

    li {
      margin: .5rem 0 0;
    }
  }

  &_error {
    text-align: center;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .current {
    border: 1px solid;
    padding: 0 .5rem;
  }

  .dots {
    border: none;
  }
}

.footer {
  //box-shadow: $box-shadow2;
  margin-top: 4rem;
  padding: 1rem 0;
  border-top: 1px solid;

  &__container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1200px;
    flex-direction: column;
    gap: 2rem;
  }

  &__nav {
    &-list {
      flex-wrap: wrap;
      justify-content: center;
      display: flex;
      gap: 1rem 2rem;

      a {
        color: $color;
        align-items: center;
        gap: .5rem;

        &:hover {
          color: $color;
        }
      }
    }
  }

  &__logo {
    text-align: center;
    user-select: none;

    a {
			font-family: 'SansSerifBldFLF', sans-serif;
      font-size: 22px;
      line-height: .8;
      flex-direction: column;
    }
  }
}

.content {
  padding: 20px;
  width: 75%;

  &__title {
    font-size: 2rem;
    margin: 0 0 1rem;
  }

  p {
    margin: 10px 0;
  }
}

.subpage {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__content {
    max-width: 900px;
    margin-top: 2rem;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      text-align: center;
      gap: 3.5rem 0;

      li {
        flex-basis: calc(33.33% - 3.5rem);

        a {
          font-size: $font-size;
          flex-direction: column;
          gap: .5rem;
        }

        span {
          font-weight: 600;
        }
      }

      .fa-light {
        font-size: $font-size5;
      }
    }
  }
}

.options {
  .options__item {
    a {
      color: $color;
      border-bottom: 0 !important;

      .fa-light.fa-toggle-on {
        color: $color;
      }
    }
  }

  .options__content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem 1rem;

    span {
      cursor: pointer;

      &:hover {
        opacity: $opacity;
      }
    }
  }
}

.fa-toggle-on::before {
  color: $color;
}

#tradingview_1fb38 {
  height: 100vh;
  overflow-y: hidden;
}

.embed {
  .header {
    display: none;
  }

  .footer {
    display: none;
  }

  .wrapper {
    height: auto !important;
  }

  .nav {
    padding: 1rem 0 1rem;
    margin: 0;
  }

  .container {
    width: 100%;
  }

  &__link {
    display: flex;
    justify-content: center;
    padding: 2rem 0 1rem;
    margin: 0;

    a {
      font-size: $font-size2;
      color: $color4;
      align-items: center;
      gap: .5rem;
    }
  }
}

.info-block {
  width: 100%;

	&__control {
		display: flex;
	  justify-content: center;
	  flex-flow: wrap;
	  margin: 3rem 0 0;
	  gap: 1rem;

	  a, button {
	    font-size: $font-size2;
	    //color: $color4;
	    @include text-decoration;
	  }
	}

	&__data {
    display: flex;
    gap: .5rem 1rem;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      display: flex;
      gap: 5.25px;
    }

		&-team {
			display: flex;

      a {
        border-bottom: 1px solid;
      }
		}
	}

	&__info {
    display: flex;
    gap: 1rem;

    &-item {
      display: flex;
    }
	}

  &__source {
		width: 100%;
		padding: .5rem;
		height: 12rem;
		border: 1px solid;
		overflow: auto;
		margin: .5rem 0;

		ul {
			margin: 0 !important;
		}

		li {
	    list-style: none !important;

	    &:last-of-type {
	      margin-bottom: 0 !important;
	    }
		}

		&-title {
			margin-bottom: .5rem;
			color: $color5;
	    border-bottom: 1px solid $color5;
		}
  }
}

:fullscreen {
  .header,
  .container__title,
  .container__2x_top,
  .info-block,
  .info-container,
  .footer {
    display: none;
  }

  .nav {
    margin: 1rem 0 1rem;
  }

  .container {
    width: 100%;
  }
}

.solitaire {
	margin: auto !important; // delete
}

@import 'dark-theme';
@import 'modal';
@import 'rtl';
@import 'media';
@import 'select';
@import 'loading';

@import url(//fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap);