@import 'variables';

@media (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 1rem;
  }

  .content {
    width: 100%;
    padding: 20px 0;
  }

  .header__container,
  .footer__container {
    width: 100%;
    padding: 0 1rem;
  }
}

@media (max-width: 961px) {
  .header {
    &.open-menu {
      .header__nav {
        display: block;
      }

      .header__menu-toggle {
        position: fixed;
        margin: 0;
        z-index: 104;
        right: 1rem;

        &:before {
          content: "\f00d";
        }
      }
    }
  }

  .submenu__list-column ul {
    gap: 1rem;
  }

  .nav__options {
    .options {
      width: calc(100% - 2rem);
    }
  }

  .nav__content-list {
    li {
      &.nav__options {
	      & > a span {
	        display: none;
	      }
      }
    }
  }

  .modal {
    &__wrap {
      width: calc(100% - 2rem);

      &_info {
        max-width: calc(100% - 2rem);
      }
    }
  }

	.subpage__content-list li {
		flex-basis: calc(50% - 0rem);
	}
}

@media (max-width: 768px) {
  .modal__content ul {
    margin: 1rem 0 0 1rem;
	}
}

@media (max-width: 550px) {
}

@media (max-width: 360px) {
}