.rtl {
	.header {
		&__logo a {
			direction: ltr;
		}

		&__toggle {
			.toggle:not(checked) + label {
        padding: 0;
			}
		}
  }

	.nav {
	  &__options {
	    .options {
	      left: 50%;
	    }

	    .options__close {
	      left: 21px;
	      right: initial;
	    }
	  }
	}

  .submenu__list {
    right: initial;
  }

// 	.cookie {
//     right: unset;
//     left: 2rem;
//
// 	  &__close {
// 	    left: 3px;
// 	    right: initial;
// 	  }
// 	}

	.modal {
	  &__close {
	    left: 21px;
	    right: initial;
	  }

		&__content {
			ul {
				margin: .5rem 2rem 0 0;
			}
		}

		#embed-textarea {
			direction: ltr;
		}
	}

  .footer {
		&__logo a {
			direction: ltr;
		}
  }

  .checkbox:not(checked) + label {
    //padding: 0 28px 0 0;

    &:before, &:after {
      right: 0;
      left: initial;
    }
  }

  .toggle {
    &:not(checked) + label {
      padding: 0 28px 0 0;

      &:before {
        left: initial;
        right: 0;
      }

      &:after {
        left: initial;
        right: 0;
      }
    }
  }
}