[select-directive].settimer__label.settimer__label_select {
  height: calc(32px + 32px);
}
[select-directive] {
  height: 32px;
}

.select {
  position: absolute;
  background-color: white;

  &__main {
    position: relative;
    display: inline-block;
    width: 400px;
    line-height: 30px;
  }

  &__baseline {
    position: relative;
    display: inline-block;
    overflow: visible;
    width: 100%;
    vertical-align: top;
    font: inherit;
  }
  &__search {
    color: $color;
    padding: 0 25px 0 8px;
    font: inherit;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    &:focus {
      & ~ .select__styling {
        border: 1px solid $color;
        box-shadow: inset 0 0 0 1px $color;
      }

      & ~ .select__arrow:before {
        transform: rotate(-180deg) translateY(-50%);
      }
    }
  }

  &__control {
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;

    &:after {
      content: "\A0";
    }

    &.select__styling {
      line-height: 30px;
      padding: 0 14px;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      text-decoration: none;
      border-radius: 3px;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      transition: border 0.1s ease-out, box-shadow 0.1s ease-out;
    }
  }

  &__styling {
  }

  &__arrow {
    width: 35px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    text-align: center;

    &:before {
      transition: transform 0.1s ease-out;
      display: block;
      top: 50%;
      transform: translateY(50%);
    }
  }

  &__menu {
    top: calc(100% + 8px);
    max-height: 236px;
    padding: 5px 0;
    position: absolute;
    left: 0;
    overflow-y: auto;
    min-width: 100%;
    //box-shadow: $box-shadow;
    background-color: white;
    z-index: 105;

    &.select__menu_active {
      display: block;
    }

    &-item {
      display: block;
      overflow-x: hidden;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 15px 0 34px;
      margin-top: 0;
      border-top: none;

      &[role="option"].focused {
        background-color: $color15;
      }

      &_active {
        background-color: $color15;
      }

      &:not([disabled]):hover {
        background-color: $color15;
      }

      &_not_found {
        color: lightgray;
      }
    }

    &-item_not_found {
      display: none;
    }

    &-item_not_found:first-child {
      display: block;
    }
  }
}