.loading {
  text-align: center;
  transition: all linear .5s;
  opacity: 1;
}

.loading.ng-hide-add-active {
  opacity: 0;
}

@keyframes animation-name {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hide-loading {
  opacity: 1;

  &.ng-hide-remove-active {
    animation-name: animation-name;
    animation-duration: .1s;
    animation-delay: .6s;
    animation-timing-function: ease-in;
    animation-direction: alternate;
    //opacity: 1;
  }

  &.ng-hide-animate {
    opacity: 0;
  }
}